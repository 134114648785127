.container-recom {
    text-align: center;
    padding: 40px 0;
    background-size: contain;

    @media (min-width: 992px) {
        background: transparent;
    }

    .recom-verde {
        color: #253746;
        font-weight: 300;
        font-size: 28px;

        @media (min-width: 992px) {
            font-size: 35px;
        }
    }

    .recom-gris {
        color: grey;
        font-size: 30px;
        font-weight: 700;

        @media (min-width: 992px) {
            font-size: 40px;
        }
    }
    
    .linea-amarilla {
        height: 5px;
        background-color: #CED9E5;
        width: 100px;
        margin: 25px auto 60px;

        @media (min-width: 992px) {
            margin: 25px auto 45px;
        }
    }

    .recom-info {
        padding: 0 30px;
        margin: 10px 0;
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        color: #707176;

        @media (min-width: 992px) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    .nueva {
        margin: 40px auto 10px;
        .boton-texto {
            font-size: 28px;
        }

        @media (min-width: 992px) {
            display: none;
        }
    }
}